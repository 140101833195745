import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";

function TrialBalance() {
    const classes = useStyles();
    const [companyColor,setcompanyColor] = useState("")

    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType'))
    },[])

    const [TrailBalance,setTrailBalance] = useState([]);
    const [isLoading,setisLoading] = useState(false)
    

    const TrailBalanceRow =[
        { field: 'Voucher', headerName: 'Voucher', width: 150   },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]


    return (
      <>
       {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
          <div className="balacepoupformmain trailBalance_main" >
            <div className="balacepoupformleft allfont">

                {/* From Date   <->  To Date */}
                <div className="row">
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">From &nbsp;&nbsp; </p>
                            <input type="date" className="" id="FDate" name="FormDate" />

                        </div>
                    </div>
                    <div className="col-sm-6 col-6 col-md-6">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">To&nbsp;&nbsp; </p>
                            <input type="date" className="" id="TDate" name="ToDate" />

                        </div>
                    </div>
                 
                </div>
                {/* opening year */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Only Year Opening</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="" value="" />Yes
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="" value="" />No

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* Report Format */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Report Format</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="" value="" />Descriptive
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="two" name="" value="" />Short
                            </li>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="three" name="" value="" />Comperitive
                            </li>
                        </div>
                    </div>
                </div>
                {/* TYpe Of Account */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Type Of Account</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />All A/C
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Non-Zero

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* statement print sequence */}
                <div className="row" style={{ padding: '5px' }}>
                    <div className="col-sm-3 col-3 col-md-3 ">
                        <div className="row" style={{height:'100%'}}>
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Statement Print Request</p>
                        </div>
                    </div>
                    <div className="col-sm-9 col-9 col-md-9">
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6 ">
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Alphabetical
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Summarised
                            </div>
                        </div>
                        <div className="row insideRow">
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Grouped By Classification
                            </div>
                            <div className="col-sm-6 col-6 col-md-6">
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Grouped By reference
                            </div>
                        </div>

                    </div>

                </div>
                {/* Add Opening Balance */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2"> Add Opening Balance </p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />Yes
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <input type="radio" className="form-check-input" id="" name="sales_officer" value="" />No

                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">

                        </div>
                    </div>
                </div>
                {/* Account Closing Balance */}
                <div className="row">
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <p className="form-check-label text-dark my-1" for="inlineRadio2">Account Closing Balance</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="" />All
                            </li>
                        </div>

                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="" />Debit<br /> Only
                            </li>
                        </div>
                    </div>
                    <div className="col-sm-3 col-3 col-md-3">
                        <div className="">
                            <li >
                                <input type="radio" className="form-check-input" id="one" name="sales_officer" value="" />Debit<br />Only
                            </li>
                        </div>
                    </div>
                </div>
                {/* Print Account Code*/}
                <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <input type="radio" className="form-check-input" id="" name="Print_Account_Code" value="" />Print Account Code

                    </div>
                </div>
                {/* Group Selection*/}

                <div className='row text-center'>
                    <div className='col-sm-12 col-12 col-lg-12'>
                        <div className='groupSelection'>
                            <br />

                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />MArketing Expense
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Gst Libelities
                            </li>
                            <li >
                                <input type="checkbox" id="" name="sales_officer" value="" />Cheque In Hand
                            </li>

                        </div>
                    </div>
                </div>
                <div className='row '>
                <div className='col'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} >EXCEL</Button></div>
                <div className='col'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} >VIEW</Button></div>
                <div className='col'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} >PDF</Button></div>
                <div className='col'> <Button variant="contained" className={classes[companyColor?.split(',')[0]]} >EXIT</Button></div>
                </div>
                <div className="d-flex mt-2" style={{ width: '100%',background:'#0078d4' }}>
                    
                </div>

            </div>
            <div className="balacepoupformright">
                <div className='table animatingBottom' style={{height:"600px",width:"100%"}}>
          <DataGrid
          rows={TrailBalance}
          columns={TrailBalanceRow}
          // loading={loading}
          pageSize={5}
          className={classes[companyColor?.split(',')[0]]}
        //   getRowId={(row) => row.Voucher}
        //     onSelectionModelChange={(ids) => {
        //       const selectedIDs = new Set(ids);
        //       const selectedRows = tabledata.filter((row) =>
        //         selectedIDs.has(row.Voucher),
             
        //       );
    
        //     }}
            {...TrailBalance}
          />
          </div>
            </div>
        </div>
      </>
    );
}

export default TrialBalance;
import { Button } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";

function AccountStatus() {
    const classes = useStyles();

    const [TrailBalance,setTrailBalance] = useState([]);
    const [acconutstatus,setacconutstatus] = useState([]);
    const [companyColor,setcompanyColor] = useState("")

    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType'))
    },[])

    const acconutstatusclm =[
        { field: 'Voucher', headerName: 'Voucher', width: 150   },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]



    return (
        <div className="balacepoupformmain " >
        <div className="colectionreportform" style={{color:'#0b5aa4', paddingTop:'10px',width: '100%' , display:'flex',justifyContent:'space-between' }}>
        
       <div style={{display:'flex', marginTop:'5px'}}>
       
             <div style={{width:'',lineHeight:'px'}}>
                <span >Account Classification </span>
                </div>       
             <div  style={{lineHeight:'px', width:'', marginBottom:'5px', marginLeft:'5px'}}>
             <select style={{width:'170px'}}>
             <option value="">All  </option>
             <option value="option1">Option 1</option>
             <option value="option2">Option 2</option>
             <option value="option3">Option 3</option>
             <option value="option3">Option 4</option>
           </select>
          
           </div>
             </div>
       
       
       <div>
       <div className="checkboxes-d-flex" style={{justifyContent:'space-between',width:'500px',marginTop:'-10px'}}>
               <p >Financial Year : <span><input type="date" /> <input type="date" /> <input type="checkbox" id="one" name="sales_officer" value=""  /><span style={{fontSize:'12px'}}>Report in Local Currency</span>  </span> </p> 
               </div>
       
       </div>
       
       <div className="d-block collectionreportbtns" style={{justifyContent:'space-between',width:'170px'}}>
       <button type="button" style={{width:'100px'}} className={`${classes[companyColor?.split(',')[0]]}  my-1`}>LOAD DATA</button>
         
       </div>
        </div>
       
         
         <div className='table animatingBottom whiteheader'  style={{height:"336px",width:"100%",background:'#fff'}}>
               <DataGrid
               rows={acconutstatus}
               columns={acconutstatusclm}
               pageSize={4}
               className={classes[companyColor?.split(',')[0]]} 
               // onCellClick={handlePendingRfqsCellClick}
               // getRowId={(row) => row.RequestNO}
                 // checkboxSelection
                
                 {...acconutstatus}
               />
               </div>
       
        </div>
       
    );
}
export default AccountStatus;
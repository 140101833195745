import { Button, Checkbox } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import useStyles from "../PartyStockLedger/style";

function AllwarehouseStock() {
    const classes = useStyles();

   
    const [allwarehouseStock,setallwarehouseStock] = useState([]);
    const [tabledata,settabledata] = useState([]);
    const [formdetails,setformdetails] = useState([])
    const [companyColor,setcompanyColor] = useState("")

    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType'))
    },[])

    const LedgerTblRow =[
        { field: 'Voucher', headerName: 'Voucher', width: 150   },
        { field: 'Nart', headerName: 'Naration', width: 150 },
        { field: 'Dabit', headerName: 'Debit', width: 150 },
        { field: 'Credit', headerName: 'Credit', width: 150 },
        { field: 'Currency', headerName: 'Currency', width: 150 },
        { field: 'atVal', headerName: '@', width: 150 },
    ]



    return (
        <div className="balacepoupformmain d-flex" >
        <div className="balacepoupformleft">
        <div className="checkboxes-d-flex" style={{justifyContent:'space-between',width:'98%',marginTop:''}}>
             <p style={{color:'#000'}}>Stock As On  :&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><input type="date" /></span> </p>
            
     
             </div>
             {
               formdetails?.map((item,index)=>(
               <center style={{color:'#fff',fontWeight:'900'}}>{item.topClientName}</center>
               ))
             }
                <div style={{color:'#000', fontsize: '16px',fontWeight:'900'}}>Group Selection</div>
             <div>
             <br />
             <div className="scrollerDiv"  style={{height:"450px"}}>
     
             <Checkbox  label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>33 (STRUCTURE STEEL)</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL ITEMS</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>Cables</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>CIVIL</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ELECTRICAL CABLES & FITTINGS</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
              <br/>
              <Checkbox label="Rate" defaultChecked size="small" />
              <span style={{color:'#000'}}>ALL CATEGORIES</span>
             </div>
              </div>
              <br/>
              <div className="d-flex mt-2" style={{width:'89%'}}>
              <Button   className={classes[companyColor?.split(',')[0]]} variant="contained">EXCEL</Button>
              <Button  className={classes[companyColor?.split(',')[0]]} variant="contained">VIEW</Button>
               <Button  className={classes[companyColor?.split(',')[0]]} variant="contained">PDF</Button>
               <Button  className={classes[companyColor?.split(',')[0]]} variant="contained">EXIT</Button>
              </div>
     
             </div>
             <div className="balacepoupformright"> 
             <div className='table animatingBottom' style={{height:"570px",width:"100%", marginTop:'px'}}>
           <DataGrid
           rows={tabledata}
           columns={LedgerTblRow}
           pageSize={4}
           className={classes[companyColor?.split(',')[0]]}
           // getRowId={(row) => row.RequestNO}
         
           //   onSelectionModelChange={(ids) => {
           //     const selectedIDs = new Set(ids);
           //     const selectedRows = tabledata.filter((row) =>
           //       selectedIDs.has(row.RequestNO),
              
           //     );
               
           //     console.log(selectedRows)                                                     
           //     setSelectedRows(selectedRows);
           //     // setfirstname(selectedRows.mcode)
     
           //   }}
             {...tabledata}
           />
           </div>
           </div>
           </div>
       
    );
}
export default AllwarehouseStock;
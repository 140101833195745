import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel, ListItemText
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid
} from "recharts";
import List from '@mui/material/List';
import noti from '../../../images/hrx.png'
import ListItem from '@mui/material/ListItem';
import axios from "axios";
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";
// Utiles
import PageTitle from "../../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../../images/pos/SalesAnalysis.png';
import loadbtn from '../../../images/buttonloader.gif';
import SaleItemImg from '../../../images/pos/SaleItem.png';
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
import { COMPANY_CODE, GET_CATEGORY_SALE_REPORT, GET_COLLECTION_REPORT, GET_GRAPH_DATA, GET_ITEMS_BELOW_ROL, GET_SALES_ANALYSIS_COUNT, GET_TOP_TEN_SALE_ITEM, GET_TOTAL_BILL_OF_DAY, GET_TOTAL_BILL_OF_Day, LOGIN_DETAILS } from "../../../constant";
const mainChartData = getMainChartData();


const color = [
  "#3DDEFA", "#34C2FF", "#4986FF", "#2055BD","#1204c1","#883be5","#d65fff","#e01ee9","#e12856","#e13e3e"
]
const color2 = [
  "#883be5","#d65fff","#e01ee9","#e12856","#e13e3e"
]


export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
 

  var today = new Date().toISOString().slice(0, 10); // get today's date in ISO format (yyyy-MM-dd)
  var toda = new Date();
  var tomorrow = new Date(toda);
  tomorrow.setDate(tomorrow.getDate() + 1);
  var nextday = tomorrow.toISOString().slice(0, 10);
  const [startdate, setstartdate] = useState(today)    // need to set here today variable 
  const [enddate, setenddate] = useState(nextday);
  const [todaysdate, settodaysdate] = useState("")

  // local
  const [mainChartState, setMainChartState] = useState("monthly");
  const [billoftheday, setbilloftheday] = useState(1);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [topTenSaleItem, settopTenSaleItem] = useState([])
  const [ChartCategory, setChartCategory] = useState([])
  const [salsAnalysis, setsalsAnalysis] = useState(0);
  const [currentLiveEnquiris, setCurrentLiveEnquiris] = useState(0);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [pendingRFQ, setPendingRFQ] = useState(0);
  const [topClientOrderCount, setTopClientOrderCount] = useState(0);
  const [TopClientOrdername, setTopClientOrdername] = useState("")
  const [topVendorCount, setTopVendorCount] = useState(0);
  const [TopVendorname, setTopVendorname] = useState("")
  const [topCilentCount, setTopCilentCount] = useState(0);
  const [TopCilentname, setTopCilentname] = useState("")
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [isform, setisform] = useState(false)
  const [companyColor, setcompanyColor] = useState("")
  const [companyBG, setcompanyBG] = useState("")
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [nowdate, setnowdate] = useState("");
  const [collectionData, setCollectionData] = useState({
    "FormDate": startdate,
    "ToDate": enddate,
    "CompanyNumber": COMPANY_CODE,
    "strWh": "",
    "SystemName": "",
    "Euser": "",
    "Category": ""
  });

  const PieChartData = [
    { name: "Group A", value: ChartCategory[0]?.Quantity, color: "primary" },
    { name: "Group B", value: ChartCategory[1]?.Quantity, color: "secondary" },
    { name: "Group C", value: ChartCategory[2]?.Quantity, color: "warning" },
    { name: "Group D", value: ChartCategory[3]?.Quantity, color: "success" },
    { name: "Group E", value: ChartCategory[4]?.Quantity, color: "success" },
    { name: "Group F", value: ChartCategory[5]?.Quantity, color: "success" },
    { name: "Group G", value: ChartCategory[6]?.Quantity, color: "success" },
    { name: "Group H", value: ChartCategory[7]?.Quantity, color: "success" },
    { name: "Group I", value: ChartCategory[8]?.Quantity, color: "success" },
    { name: "Group J", value: ChartCategory[9]?.Quantity, color: "success" },
  ];
  const [TotalCollection, setTotalCollection] = useState([
    { name: "0", count: "Card" },
    { name: "0", count: "Cash" },
    { name: "0", count: "Cheque" },
    { name: "0", count: "Bank Transfer" },
  ]);


  const [popupclass, setpopupclass] = useState("display-none")
  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]

  const pendingRFQsclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 150 },
    { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },


  ]

  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

 

  // useEffect(()=>{
  //   const companyCredencial = localStorage.getItem('cmpnyType')
  //   const UserCredencial    = localStorage.getItem('username')

  //     if (!companyCredencial ||  companyCredencial === null ) {
  
  //      window.location.href ="/";
  //    }
  //    else {
  //     history.replace("/dashboard");
  //   }
 
  //    if(!UserCredencial){
  //      history.push("/login"); 
  //    }
    

  //    const handleBeforeUnload = (event) => {
  //      event.preventDefault();
  //      event.returnValue = "";
       
      
  //    };
    
  //  window.addEventListener("beforeunload", handleBeforeUnload);

  //  return () => {
  //    window.removeEventListener("beforeunload", handleBeforeUnload);
  //  };
  // },[])

  function getMonthName(monthNumber) {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    return months[monthNumber - 1] || null;
  }

  useEffect(() => {
    setcompanyColor(localStorage.getItem('cmpnyType'))
   
 
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    //---------- GET ITEM BELOW ROLFOR DASHBOERD----------------
    axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      console.log("bill of the day ")
      console.log(response)
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data)
        setItemsBelowRol(response.data.Data);
      }
      else {
        setItemsBelowRol(["NO DATA FOUND "])
      }
    })


    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    console.log(formattedDate);

    settodaysdate(formattedDate)
    getreports(startdate, enddate)
  }, [startdate, enddate]);

  // Get Report Function => getreports(startdate , enddate)

  const getreports = (start, end) => {

    //---------- Total Collection----------------

    axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data.BillCount);
          setbilloftheday(response.data.Data.BillCount)

        }
      })

    //---------- Total Collection----------------

    collectionData.FormDate = start
    collectionData.ToDate = end
    console.log(collectionData)

    axios.post(GET_COLLECTION_REPORT, collectionData)
      .then((response) => {
        console.log(response);
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setTotalCollection([
            { name: response.data.Data[0].VISA ? response.data.Data[0].VISA : "0", count: "Card" },
            { name: response.data.Data[0].CASH ? response.data.Data[0].CASH : "0", count: "Cash" },
            { name: response.data.Data[0].CHEQUE ? response.data.Data[0].CHEQUE : "0", count: "Cheque" },
            { name: response.data.Data[0].Credit ? response.data.Data[0].Credit : "0", count: "Bank Transfer" },
          ])

          console.log(TotalCollection)
        }
      })

    //---------- Sales Analysis----------------
    axios.post(GET_SALES_ANALYSIS_COUNT, { "CompanyNumber": COMPANY_CODE, "Warehouse": LOGIN_DETAILS["110NOIDA"], "FYear": LOGIN_DETAILS.FINANCIAL_YEAR, "CHSERIES": LOGIN_DETAILS.CHSERIES_RSL })
      .then((response) => {
        console.log("sale analysis")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data.BillCount);
          setsalsAnalysis(response.data.Data.BillCount)

        }
      })

    //---------- TOP TEN SALE ITEMS----------------

    axios.post(GET_TOP_TEN_SALE_ITEM, { "CompanyNumber": COMPANY_CODE, "fromDate": start, "toDate": end })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          settopTenSaleItem(response.data.Data)

        }
      })

    //---------- Category pie chart  ----------------

    axios.post(GET_CATEGORY_SALE_REPORT,
      { "CompanyNumber": COMPANY_CODE, "FormDate": start, "ToDate": end })
      .then((response) => {
        console.log("Category pie chart")
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data);
          setChartCategory(response.data.Data)

        }
      })

    //---------- GET GRAPH DATA FOR DASHBOERD----------------
    axios.post(GET_GRAPH_DATA, { "CompanyNumber": 304, "fromDate": start, "toDate": end})
      .then((response) => {
        console.log("graph res")
        console.log(response)
        if (response.data.IsSuccess === true) {
          setGraphDate(response.data.Data);

        }
      })

  }

  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1; 
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }



  const getreportseightmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }

  const getreportssixmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))
  }
  //date before respective Months => (a) 


  const getreportsthreemonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);
    const formattedDate = threeMonthsAgo.toISOString().slice(0, 10);
    setstartdate(formattedDate)
    setenddate(new Date().toISOString().slice(0, 10))
    // // hit apply BUtton  //So  every thing is Updated 
    // // document.querySelector('#getReport').click()
    getreports(formattedDate, new Date().toISOString().slice(0, 10))

  }


  // graph  start

  
 // new method 
 console.log("graphdate")
 console.log(graphDate)
const newData = graphDate.map((item) => {
if (item.TP === 'SALE') {
  return { ...item, allSales: item.TotalSales };
} else if (item.TP === 'PURCH') {
  return { ...item, allPurchase: item.TotalSales };
} else {
  return item;
}
});
console.log("new data ")
console.log(newData);
const groupedData = newData.reduce((acc, curr) => {
const monthKey = `${curr.Year}-${curr.Month}`;

if (!acc[monthKey]) {
  acc[monthKey] = {
    Year: curr.Year,
    Month: curr.Month,
    allSales: 0,
    allPurchase: 0
  };
}

if (curr.TP === "SALE") {
  acc[monthKey].allSales += curr.TotalSales;
} else if (curr.TP === "PURCH") {
  acc[monthKey].allPurchase += curr.TotalSales;
}

return acc;
}, {});

const finalData = Object.values(groupedData);
console.log("main are chart arr");
console.log(finalData);

const updatedArr = finalData.map((data, index, array) => {
  let cumulativeSales = data.allSales;
  let cumulativePurchase = data.allPurchase;

  // Calculate cumulative sales and purchase values for previous months
  for (let i = 0; i < index; i++) {
    cumulativeSales += array[i].allSales;
    cumulativePurchase += array[i].allPurchase;
  }

  return {
    Year: data.Year,
    Month: data.Month,
    allSales: cumulativeSales,
    allPurchase: cumulativePurchase
  };
});
console.log("final Updated")
console.log(finalData)
console.log(updatedArr)


  // ------------------graph end ------------------

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };

  const handleclosepopup = () => {
    setpopupclass("display-none")
  }
  const handleclosepopupfullscreen = () => {
    setisform(false)
    setpopupclass("display-block")
  }

  const handlecurrentliveenquirydata = () => {
    setcurrentlivepopup(true)
    setpendingreqpopup(false)
    setpendingPOpopup(false)
    setcrediorbalancepopup(false)
    setdibitorbalancepopup(false)
    setpopupclass("display-block")
    setpopupHeader("Current Live Enquiries")
    //---------- GET CURRENT LIVE ALL ENQUIRIS FOR DASHBOERD----------------
    // axios.get(GET_CURRENT_ALL_LIVE_ENQUIRIS).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setCurrentLiveEnquirispopupdata(response.data.Data)

    //   }
    // })
  }

  const handlependingRFQs = () => {
    setpopupclass("display-block")
    setpendingreqpopup(true)
    setcurrentlivepopup(false)
    setcrediorbalancepopup(false)
    setpendingPOpopup(false)
    setdibitorbalancepopup(false)
    setpopupHeader("Pending RFQs")
    //---------- GET PENDING RFQS ----------------
    // axios.get(GET_CURRENT_ALL_PENDING_RFQ).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setpendingRFQspopupdata(response.data.Data)

    //   }
    // })

  }

  const handlependingInvoice = () => {
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(true)
    setcrediorbalancepopup(false)
    setdibitorbalancepopup(false)
    setpopupclass("display-block")
    setpopupHeader("  PO Pending Invoice")
    //---------- GET PENDING RFQS ----------------
    // axios.get(GET_CURRENT_ALL_PENDING_INVOICE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setPendingpopopupdata(response.data.Data)

    //   }
    // })

  }

  const handledebitorBalance = () => {
    setpopupclass("display-block")
    setdibitorbalancepopup(true)
    setcrediorbalancepopup(false)
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(false)
    setpopupHeader("Debitor Balance")
    //---------- GET debitor balance----------------
    //  axios.get(GET_DEBITOR_BALANCE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setdebitorbalancedata(response.data.Data)


    //   }
    // })
  }

  const handlecreditedbalance = () => {
    setpopupclass("display-block")
    setdibitorbalancepopup(false)
    setcrediorbalancepopup(true)
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(false)
    setpopupHeader("Creditor Balance")
    //---------- GET debitor balance----------------
    //  axios.get(GET_CREDITOR_BALANCE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setcreditorbalancedata(response.data.Data)


    //   }
    // })


  }

  //  const removepopup =() =>{
  //   setpopupclass("display-none")
  //  }


  const openRFQsForm = (a, b, c, d) => {
    debugger;
    console.log(a)
    console.log(b)
    console.log(c)
    console.log(d)
  }
  const handlePendingRfqsCellClick = (params) => {

    console.log(params.row)
    setisform(true)
    setpopupclass("display-none")

  }
  



  return (
    <>
      {/* {
        isLoading ? <div className='Commonloader'>
        </div> : null
      } */}
      <Grid container spacing={5} >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <div className="notify">
            <img  height="20px" src={noti} />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">

          <PageTitle title={`Welcome, ${loginname}`}
            button={<Button
              variant="contained"
              size="medium"
              className={classes.rastorant_mainColor}
            >
              <span className={` ${classes[companyColor]}`} >{nowexactdate()}</span>
            </Button>} />

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0">
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={7} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => setstartdate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => setenddate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <button id="getReport" className={`${classes.dashSideBtn}  ${classes.POS_primary} ${classes[companyColor + 'BG']}`} onClick={(e) => getreports(startdate, enddate)} >
                {isLoading ?<img className="loaderbtnApply"   src={loadbtn} />:"Apply" }
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={5} className={`py-1 ${classes.dashSideBtnMain}`}>
          <button className={classes.dashSideBtn} onClick={getreportsthreemonths}>
            Last 3 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportssixmonths}>
            Last 6 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportseightmonths}>
            Last 8 months
          </button>
        </Grid>
        {/*Total biil section main data */}
        <Grid item lg={4} md={4} sm={4} xs={6} className="pb-1">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Total Bills to the day</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
                  <h4 className={`fw-bold ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    {salsAnalysis}
                  </h4>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={TotalBilImg} className='dashboardcardimage w-100' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6} className="pb-1">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Total Collection</h6>
              <List className="pb-4 " sx={{ width: '100%', maxWidth: 360, height: '80%' }}>
                {TotalCollection.map((item, i) => (
                  <ListItem
                    key={i}
                    disableGutters className={`py-0 ${classes.cardSecondaryClr} ${classes[companyColor]}  `}
                    secondaryAction={
                      item.name
                    }
                  >
                    <p style={{ color: '#333' }}>{item.count}</p>

                  </ListItem>
                ))}
              </List>

            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6} className="pb-1">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Sales Analysis</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={6}>
                  <h4 className={`fw-bold ${classes.textCountInfo}  ${classes[companyColor]}`} weight="medium" noWrap>
                    {billoftheday}
                  </h4>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={SalesAnalysisImg} className='dashboardcardimage w-75 ' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={5} >
        <Grid item lg={8} md={8} sm={8} xs={12} className="py-1">
          <Grid container spacing={5} >
            <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
              <div className={`card py-1 ${classes.row1Card}`}>
                <div className="card-body pb-0">
                  <h6 className={`card-title my-0 fw-bold`}>Top 10 Best Selling Items</h6>
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={8}>
                      <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                      </h4>
                      <Grid container spacing={5} className="pt-0" >
                        <Grid item xs={6} className="pt-0">
                          {
                            topTenSaleItem?.slice(0, 5)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                        <Grid item xs={6} className="pt-0">
                          {
                            topTenSaleItem?.slice(5, 10)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={4} className="text-end">
                      <span >
                        <img src={SaleItemImg} className='dashboardcardimage w-75' /></span>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>


            <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
              <div className={`card py-1 ${classes.row1Card}`}>
                <div className="card-body pb-0">
                  <h6 className={`card-title my-0 fw-bold`}>Items below ROL </h6>
                  {ItemsBelowRol.length === 0 ? "" : <p className={`${classes[companyColor]}`} style={{ float: 'right'}}>NO ITEM FOUND</p>}
                  <Grid container item alignItems={"center"}>
                    <Grid item xs={12}>
                      <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                      </h4>
                      <Grid container spacing={5} >
                        <Grid item xs={4} className="pt-0">
                          {
                            ItemsBelowRol?.slice(0, 3)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                        <Grid item xs={4} className="pt-0">
                          {
                            ItemsBelowRol?.slice(0, 3)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                        <Grid item xs={4} className="pt-0">
                          {
                            ItemsBelowRol?.slice(0, 3)?.map((item, i) => (
                              <>
                                <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                                  <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                                </ol>
                              </>
                            ))
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12} className="py-1">
          <h6 className={`card-title my-0 fw-bold`}>Category</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <PieChart width={230} height={250}>
                <Pie data={PieChartData} cx="50%" cy="50%" innerRadius={60} outerRadius={80} label>
                  {
                    PieChartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={color[index]} />
                    ))
                  }
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="d-flex">
         <List sx={{ width: '47%', maxWidth: 360, bgcolor: 'background.paper', padding: '4px 8px ' }}>
            {ChartCategory.slice(0, 5).map((item, i) => (
              <ListItem
                key={i}
                disableGutters className={`py-0 ${classes.cardSecondaryClr} ${classes[companyColor]}  `}
                secondaryAction={
                  item.Quantity
                }
              >
                <span className='px-1 m-1' style={{ background: `${color[i]}`, color: `${color[i]}` }}> co</span>
                <p className="pt-2" style={{ color: `${color[i]}` }}>{item.CATEGORY}</p>

              </ListItem>
            ))}
           
          </List>
          <List sx={{ width: '47%', maxWidth: 360, bgcolor: 'background.paper', padding: '4px 8px ' }}>
            {
              ChartCategory.length < 6 ? ` ${ChartCategory.length} categories Are Found`:
             <>
              {ChartCategory.slice(5, 10).map((item, i) => (
                <ListItem
                  key={i}
                  disableGutters className={`py-0 ${classes.cardSecondaryClr} ${classes[companyColor]}  `}
                  secondaryAction={
                    item.Quantity
                  }
                >
                  <span className='px-1 m-1' style={{ background: `${color2[i]}`, color: `${color2[i]}` }}> co</span>
                  <p className="pt-2" style={{ color: `${color2[i]}` }}>{item.CATEGORY}</p>
  
                </ListItem>
              ))}
             </>
            }
          
          </List>
         </div>
        </Grid>

      </Grid>
      <Grid container spacing={5} className="my-2" >
        <Grid item lg={12} md={12} sm={12} xs={12} style={{ bgcolor: 'background.paper'}} className="my-2" >
          {
            updatedArr.length > 0 ?
            <>
             <div onResize={handleResize}>
             <ResponsiveContainer width="100%" height={250}>
            <AreaChart data={updatedArr}  // duplicate of graphdate 
              margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis dataKey={(data) => getMonthName(data.Month)} />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Area type="monotone" dataKey="allSales" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
              <Area type="monotone" dataKey="allPurchase" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
            </AreaChart>
          </ResponsiveContainer>
             </div>
             
            </>:<p className={`${classes[companyColor]}`} >NO RECORD FOUND</p>
          }
    
        </Grid>


        {/* ----popup-- */}
        <div className={popupclass}>
          <div className="popupbg" >
            <section className='popup-main'>
              <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                <h4 style={{ color: "#fff" }}>{popupHeader}</h4>
                <p style={{ color: "#fff" }} className="crossicon" onClick={handleclosepopup}>&#10006;</p>
              </div>

              <div className="table-responsive">
                {
                  pendingreqpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={pendingRFQspopupdata}
                        columns={pendingRFQsclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = pendingRFQspopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...pendingRFQspopupdata}
                      />
                    </div>

                    :
                    ""}

                {
                  currentlivepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={CurrentLiveEnquirispopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = CurrentLiveEnquirispopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...CurrentLiveEnquirispopupdata}
                      />
                    </div> : ""
                }
                {
                  pendingPOpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={Pendingpopopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = Pendingpopopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...Pendingpopopupdata}
                      />
                    </div> : ""
                }
                {
                  dibitorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={debitorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = debitorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...debitorbalancedata}
                      />
                    </div> : ""
                }
                {
                  crediorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={creditorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = creditorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...creditorbalancedata}
                      />
                    </div> : ""
                }

              </div>
            </section>
          </div>
        </div>
        {/* ------- */}
        <div className="d">
        </div>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
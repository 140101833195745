import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
btnForLedger:{
  background:"#0b5aa4",
  color:"white",
},
restaurant: {
  backgroundColor: theme.palette.primary.Rastorant_primary,
  color: theme.palette.primary.whiteColor,
},
pos: {
  backgroundColor: theme.palette.primary.POS_primary,
  color: theme.palette.primary.whiteColor,
},
production: {
  backgroundColor: theme.palette.primary.Production_primary,
  color: theme.palette.primary.whiteColor,
},
order_management: {
  backgroundColor: theme.palette.primary.OrderMngmt_primary,
  color: theme.palette.primary.whiteColor,
},
}
));

import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid, TextField,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button, InputLabel
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  Line,
  Area,
  PieChart,
  Pie, Tooltip,
  Cell, LineChart,
  YAxis,
  XAxis, CartesianGrid
} from "recharts";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import axios from "axios";
// styles
import useStyles from "./styles";
// Utiles
import PageTitle from "../../../Utiles/PageTitle/PageTitle";
import Searchbar from "../../../Utiles/Searchbar/SearchBar";
import TotalBilImg from '../../../images/pos/totalBillImg.png';
import SalesAnalysisImg from '../../../images/pos/SalesAnalysis.png';
import SaleItemImg from '../../../images/pos/SaleItem.png';
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
import themes from "../../../themes";
import { COMPANY_CODE, GET_ITEMS_BELOW_ROL, GET_TOTAL_BILL_OF_DAY } from "../../../constant";
const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];
const color = [
  "#3DDEFA", "#34C2FF", "#4986FF", "#2055BD"
]

const data = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400,
    "amt": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398,
    "amt": 2210
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800,
    "amt": 2290
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908,
    "amt": 2000
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800,
    "amt": 2181
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800,
    "amt": 2500
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300,
    "amt": 2100
  }
]

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [startdate, setstartdate] = useState("")

  const [enddate, setenddate] = useState("")
  const [todaysdate, settodaysdate] = useState("")

  // local
  const [mainChartState, setMainChartState] = useState("monthly");
  const [ItemsBelowRol, setItemsBelowRol] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [loginname, setloginname] = useState(localStorage.getItem('username'))
  const [currentLiveEnquiris, setCurrentLiveEnquiris] = useState(0);
  const [totalbillCountofday, settotalbillCountofday] = useState(1)
  const [CurrentLiveEnquirispopupdata, setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata, setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata, setPendingpopopupdata] = useState([])
  const [debitorbalancedata, setdebitorbalancedata] = useState([])
  const [creditorbalancedata, setcreditorbalancedata] = useState([])
  const [pendingRFQ, setPendingRFQ] = useState(0);
  const [topClientOrderCount, setTopClientOrderCount] = useState(0);
  const [TopClientOrdername, setTopClientOrdername] = useState("")
  const [topVendorCount, setTopVendorCount] = useState(0);
  const [TopVendorname, setTopVendorname] = useState("")
  const [topCilentCount, setTopCilentCount] = useState(0);
  const [TopCilentname, setTopCilentname] = useState("")
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader, setpopupHeader] = useState("")
  const [pendingreqpopup, setpendingreqpopup] = useState(false)
  const [currentlivepopup, setcurrentlivepopup] = useState(false)
  const [pendingPOpopup, setpendingPOpopup] = useState(false)
  const [dibitorbalancepopup, setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup, setcrediorbalancepopup] = useState(false)
  const [SelectedRows, setSelectedRows] = useState([])
  const [isform, setisform] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [companyColor, setcompanyColor] = useState("")





  const [popupclass, setpopupclass] = useState("display-none")
  const currentliveenquryclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 240 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 }

  ]

  const pendingRFQsclm = [
    { field: 'topClientName', headerName: 'CLIENT NAME', width: 150 },
    { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150 },
    { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
    { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },


  ]

  const debitorbalancedataclm = [
    { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150 },
    { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150 },
    { field: 'Debit', headerName: 'DEBITED', width: 80 },
    { field: 'credit', headerName: 'CREDIT', width: 80 },
    { field: 'balance', headerName: 'BALANCE', width: 100 },
  ]

  useEffect(() => {

    setcompanyColor(localStorage.getItem('cmpnyType')?.split(',')[0])
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    // ---------- Items below ROL----------------
    axios.post(GET_ITEMS_BELOW_ROL, { "CompanyNumber": COMPANY_CODE }).then((response) => {
      if (response.data.IsSuccess === true) {
        console.log(response.data.Data)
        setItemsBelowRol(response.data.Data);
      }
    })
    //---------- GET CURRENT LIVE ENQUIRIS FOR DASHBOERD----------------
    // axios.get(GET_CURRENT_LIVE_ENQUIRIS_COUNT).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setCurrentLiveEnquiris(response.data.Data.CurrentLiveEnquiris);
    //   }
    // })


    //---------- GET TOP client order FOR DASHBOERD----------------
    // axios.get(GET_TOP_CLIENT_ORDER_COUNT).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setTopClientOrderCount(response.data.Data.topClientOrderCount);
    //     setTopClientOrdername(response.data.Data.topClientOrderName);
    //   }
    // })
    //---------- GET TOP VENDOR FOR DASHBOERD----------------
    // axios.get(GET_TOP_VENDOR_COUNT).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setTopVendorCount(response.data.Data.topVendorCount)
    //     setTopVendorname(response.data.Data.topVendorName)
    //   }
    // })

    //---------- GET TOP CLIENT FOR DASHBOERD----------------
    // axios.get(GET_TOP_CLIENT_COUNT).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setTopCilentCount(response.data.Data.topCilentCount)
    //     setTopCilentname(response.data.Data.topClientName)
    //   }
    // })
    //---------- GET PENDING RFQ FOR DASHBOERD----------------
    //  axios.get(GET_PENDING_RFQ_COUNT).then((response)=>{
    //   if(response.data.IsSuccess === true){

    //     setPendingRFQ(response.data.Data.PendingRFQ)
    //   }
    // })
    //---------- GET GRAPH DATA FOR DASHBOERD----------------
    //   axios.post(GET_GRAPH_DATA,{
    //     "StartDate":"01-03-2023",
    //     "EndDate":"10-03-2023"
    // }).then((response)=>{
    //     console.log(response)
    //     if(response.data.IsSuccess === true){
    //       setGraphDate(response.data.Data);
    //     }
    //   })
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth());

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    console.log(formattedDate);

    settodaysdate(formattedDate)
  }, []);
  const getreports = () => {
    console.log(startdate)
    console.log(enddate)
    //  ---------- TotalBillTotheday----------------
    axios.post(GET_TOTAL_BILL_OF_DAY, { "CompanyNumber": 5, "fromDate": startdate, "toDate": enddate })
      .then((response) => {
        console.log(response)
        if (response.data.IsSuccess === true) {
          console.log(response.data.Data.BillCount);
          settotalbillCountofday(response.data.Data.BillCount)

        }
      })
  }




  const getreportseightmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 8);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    console.log(formattedDate);
    var datebeforenineMOnth = formattedDate;

    //   axios.post(GET_GRAPH_DATA,{
    //     "StartDate":todaysdate,
    //     "EndDate":datebeforenineMOnth
    // }).then((response)=>{
    //     console.log(response)
    //     if(response.data.IsSuccess === true){
    //       setGraphDate(response.data.Data);
    //     }
    //     else{
    //       alert("No record Found !!")
    //     }
    //   })

  }

  const getreportssixmonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 6);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');


    var datebeforesixMOnth = formattedDate;

    // axios.post(GET_GRAPH_DATA,{
    //   "StartDate":todaysdate,
    //   "EndDate":datebeforesixMOnth
    // }).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setGraphDate(response.data.Data);
    //   }
    //   else{
    //     alert("No record Found !!")
    //   }
    // })
  }

  const getreportsthreemonths = () => {
    const today = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(today.getMonth() - 3);

    const options = { month: '2-digit', day: '2-digit', year: 'numeric', };
    const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
      .replace(/\//g, '-');

    var datebeforethreeMOnth = formattedDate;

    // axios.post(GET_GRAPH_DATA,{
    //   "StartDate":todaysdate,
    //   "EndDate":datebeforethreeMOnth
    // }).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     setGraphDate(response.data.Data);
    //   }
    //   else{
    //     alert("No record Found !!")
    //   }
    // })
  }


  const handleclosepopup = () => {
    setpopupclass("display-none")
  }
  const handleclosepopupfullscreen = () => {
    setisform(false)
    setpopupclass("display-block")
  }

  const handlecurrentliveenquirydata = () => {
    setcurrentlivepopup(true)
    setpendingreqpopup(false)
    setpendingPOpopup(false)
    setcrediorbalancepopup(false)
    setdibitorbalancepopup(false)
    setpopupclass("display-block")
    setpopupHeader("Current Live Enquiries")
    //---------- GET CURRENT LIVE ALL ENQUIRIS FOR DASHBOERD----------------
    // axios.get(GET_CURRENT_ALL_LIVE_ENQUIRIS).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setCurrentLiveEnquirispopupdata(response.data.Data)

    //   }
    // })
  }

  const handlependingRFQs = () => {
    setpopupclass("display-block")
    setpendingreqpopup(true)
    setcurrentlivepopup(false)
    setcrediorbalancepopup(false)
    setpendingPOpopup(false)
    setdibitorbalancepopup(false)
    setpopupHeader("Pending RFQs")
    //---------- GET PENDING RFQS ----------------
    // axios.get(GET_CURRENT_ALL_PENDING_RFQ).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setpendingRFQspopupdata(response.data.Data)

    //   }
    // })

  }

  const handlependingInvoice = () => {
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(true)
    setcrediorbalancepopup(false)
    setdibitorbalancepopup(false)
    setpopupclass("display-block")
    setpopupHeader("  PO Pending Invoice")
    //---------- GET PENDING RFQS ----------------
    // axios.get(GET_CURRENT_ALL_PENDING_INVOICE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setPendingpopopupdata(response.data.Data)

    //   }
    // })

  }

  const handledebitorBalance = () => {
    setpopupclass("display-block")
    setdibitorbalancepopup(true)
    setcrediorbalancepopup(false)
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(false)
    setpopupHeader("Debitor Balance")
    //---------- GET debitor balance----------------
    //  axios.get(GET_DEBITOR_BALANCE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setdebitorbalancedata(response.data.Data)


    //   }
    // })
  }

  const handlecreditedbalance = () => {
    setpopupclass("display-block")
    setdibitorbalancepopup(false)
    setcrediorbalancepopup(true)
    setpendingreqpopup(false)
    setcurrentlivepopup(false)
    setpendingPOpopup(false)
    setpopupHeader("Creditor Balance")
    //---------- GET debitor balance----------------
    //  axios.get(GET_CREDITOR_BALANCE).then((response)=>{
    //   console.log(response)
    //   if(response.data.IsSuccess === true){
    //     console.log(response.data.Data)
    //     setcreditorbalancedata(response.data.Data)


    //   }
    // })


  }

  //  const removepopup =() =>{
  //   setpopupclass("display-none")
  //  }


  const openRFQsForm = (a, b, c, d) => {
    debugger;
    console.log(a)
    console.log(b)
    console.log(c)
    console.log(d)
  }
  const handlePendingRfqsCellClick = (params) => {

    console.log(params.row)
    setisform(true)
    setpopupclass("display-none")

  }
  var nowexactdate = function getFormettedDate() {
    var datentime = ""
    let today = new Date();

    // Get day, month, and year separately
    let day = today.getDate();
    let month = today.getMonth() + 1; 
    let year = today.getFullYear();

    // Create an array of month names
    let monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    // Get the month name from the array
    let monthName = monthNames[month - 1];
    datentime = `${day} ${monthName} ${year}`
    return datentime

  }

  const handleResize = () => {
    setShouldUpdate(false);
    setTimeout(() => {
      setShouldUpdate(true);
    }, 500);
  };


  return (
    <>
      {
        isLoading ? <div className='Commonloader'>
        </div> : null
      }
      <Grid container spacing={5} >
        <Grid item lg={8} md={8} sm={8} xs={12}>
          <Searchbar />
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12}>
          <div className="notify"><Alertmessage /></div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <PageTitle title={`Welcome, ${loginname}`}
            button={<Button
              variant="contained"
              size="medium"
              className={classes.pageheading}
            >
              <span className={` ${classes[companyColor]}`} >{nowexactdate()}</span>
            </Button>} />

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pt-0">
          Date Range
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={7} className="py-1">
          {/* <InputLabel id="demo-controlled-open-select-label">From Date</InputLabel> */}
          <Grid container spacing={5}>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={startdate}
                onChange={(e) => setstartdate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <TextField id="standard-basic" type="date" value={enddate}
                onChange={(e) => setenddate(e.target.value)} />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className='py-0'>
              <button className={`${classes.dashSideBtn}  ${classes.Production_primary} ${classes[companyColor + 'BG']}`} onClick={getreports} >
                Apply
              </button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid  item lg={2} md={2} sm={1} xs={1}className="py-1"></Grid> */}
        <Grid item lg={6} md={6} sm={6} xs={5} className={`py-1 ${classes.dashSideBtnMain}`}>
          <button className={classes.dashSideBtn} onClick={getreportsthreemonths}>
            Last 3 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportssixmonths}>
            Last 6 months
          </button>
          <button className={classes.dashSideBtn} onClick={getreportseightmonths}>
            Last 8 months
          </button>
        </Grid>

      </Grid>

      <Grid container spacing={6} >
        < Grid item lg={4} md={4} sm={4} xs={4} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Total FG Stock</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>


                {/* <Grid item xs={4}className="text-end">
              <span >
            <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={4} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Total WIP Stock</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        < Grid item lg={4} md={4} sm={4} xs={4} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Pending Production Order</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={10}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} className="pt-0" >
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={6} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>


                {/* <Grid item xs={4}className="text-end">
              <span >
            <img src={SaleItemImg}  className='dashboardcardimage w-75'/></span>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Items Produced</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} >
                    <Grid item xs={4} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Items below ROL</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={5} >
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(0, 3)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }

                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(3, 6)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }
                    </Grid>
                    <Grid item xs={4} className="pt-0">
                      {
                        ItemsBelowRol.slice(6, 9)?.map((item, i) => (
                          <>
                            <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1' key={i}>
                              <li className="list-group-item"><h6 className={classes.textSecondry}>{item.Item}</h6></li>
                            </ol>
                          </>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="pb-0">
          <div className={`card py-1 ${classes.row1Card}`}>
            <div className="card-body pb-4">
              <h6 className={`card-title my-0 fw-bold`}>Sales Order Pending</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12}>
                  <h4 className={`fw-bold ${classes.textCountInfo}`} weight="medium" noWrap>
                  </h4>
                  <Grid container spacing={0} >
                    <Grid item xs={2} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <h6 className={`card-title my-0 fw-bold`}>PO Number </h6>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>1. An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>2. A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>3. A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>4. A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>5. And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={2} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <h6 className={`card-title my-0 fw-bold`}>Client Name </h6>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={2} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <h6 className={`card-title my-0 fw-bold`}>Total Quantity </h6>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={2} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <h6 className={`card-title my-0 fw-bold`}>Column 1 </h6>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                    <Grid item xs={2} className="pt-0">
                      <ol className={`p-0 mt-3 mb-0 ${classes.textSecondry}`} start='1'>
                        <h6 className={`card-title my-0 fw-bold`}>Column 2 </h6>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>An item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A second item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A third item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>A fourth item</h6></li>
                        <li className="list-group-item"><h6 className={classes.textSecondry}>And a fifth one</h6></li>
                      </ol>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>


      <Grid container spacing={5} className="Graphdata" >


        <Grid item lg={12} md={12} sm={12} xs={12} className="my-2">
          <h6 className={`card-title my-0 fw-bold`}>Production Summary</h6>
          <div onResize={handleResize}>
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={data}
                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Grid>


        {/* ----popup-- */}
        {/* <div className={popupclass}>
      <div className="popupbg" >
      <section className='popup-main'>
       <div style={{display:"flex",justifyContent:"space-between", width:"90%"}}>
       <h4 style={{color:"#fff"}}>{popupHeader}</h4>
        <p style={{color:"#fff"}}  className="crossicon" onClick={handleclosepopup}>&#10006;</p>
       </div>
    
      <div className="table-responsive">
      {
        pendingreqpopup ? 
      <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
      <DataGrid
      rows={pendingRFQspopupdata}
      columns={pendingRFQsclm}
      pageSize={4}
      onCellClick={handlePendingRfqsCellClick}
      getRowId={(row) => row.RequestNO}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = pendingRFQspopupdata.filter((row) =>
            selectedIDs.has(row.RequestNO),
         
          );
          
          console.log(selectedRows)                                                     
          setSelectedRows(selectedRows);
          // setfirstname(selectedRows.mcode)

        }}
        {...pendingRFQspopupdata}
      />
      </div>
      
      :
      ""}

      {
        currentlivepopup ?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
      <DataGrid
      rows={CurrentLiveEnquirispopupdata}
      columns={currentliveenquryclm}
      pageSize={4}
      onCellClick={handlePendingRfqsCellClick}
      getRowId={(row) => row.RequestNO}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = CurrentLiveEnquirispopupdata.filter((row) =>
            selectedIDs.has(row.RequestNO),
         
          );
          
          console.log(selectedRows)                                                     
          setSelectedRows(selectedRows);
          // setfirstname(selectedRows.mcode)

        }}
        {...CurrentLiveEnquirispopupdata}
      />
      </div>:""
      }
      {
        pendingPOpopup ? 
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={Pendingpopopupdata}
        columns={currentliveenquryclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.RequestNO}
          // checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = Pendingpopopupdata.filter((row) =>
              selectedIDs.has(row.RequestNO),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...Pendingpopopupdata}
        />
        </div>:""
      }
       {
        dibitorbalancepopup ?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={debitorbalancedata}
        columns={debitorbalancedataclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.MCODE}
          // checkboxSelection
          onSelectionModelChange={(ids) => {  
            const selectedIDs = new Set(ids);
            const selectedRows = debitorbalancedata.filter((row) =>
              selectedIDs.has(row.MCODE),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...debitorbalancedata}
        />
        </div>: ""
      }
      {
        crediorbalancepopup?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={creditorbalancedata}
        columns={debitorbalancedataclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.MCODE}
          // checkboxSelection
          onSelectionModelChange={(ids) => {  
            const selectedIDs = new Set(ids);
            const selectedRows = creditorbalancedata.filter((row) =>
              selectedIDs.has(row.MCODE),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...creditorbalancedata}
        />
        </div>: ""
      }
     
  </div>
      </section>
      </div>
    </div> */}
        {/* ------- */}
        <div className="d">
        </div>
      </Grid>
      <Grid container spacing={5} className="Graphdata" >
        {/* <Grid  item lg={12} md={12} sm={12} xs={12}  className="my-2">
      <h6 className={`card-title my-0 fw-bold`}>Sale Value</h6>

        <ResponsiveContainer width="100%" height={250}>
      <AreaChart data={data}
  margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="name" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="uv" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
  <Area type="monotone" dataKey="pv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
</AreaChart>
</ResponsiveContainer>
        </Grid> */}


        {/* ----popup-- */}
        <div className={popupclass}>
          <div className="popupbg" >
            <section className='popup-main'>
              <div style={{ display: "flex", justifyContent: "space-between", width: "90%" }}>
                <h4 style={{ color: "#fff" }}>{popupHeader}</h4>
                <p style={{ color: "#fff" }} className="crossicon" onClick={handleclosepopup}>&#10006;</p>
              </div>

              <div className="table-responsive">
                {
                  pendingreqpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={pendingRFQspopupdata}
                        columns={pendingRFQsclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = pendingRFQspopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...pendingRFQspopupdata}
                      />
                    </div>

                    :
                    ""}

                {
                  currentlivepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={CurrentLiveEnquirispopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = CurrentLiveEnquirispopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...CurrentLiveEnquirispopupdata}
                      />
                    </div> : ""
                }
                {
                  pendingPOpopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={Pendingpopopupdata}
                        columns={currentliveenquryclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.RequestNO}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = Pendingpopopupdata.filter((row) =>
                            selectedIDs.has(row.RequestNO),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...Pendingpopopupdata}
                      />
                    </div> : ""
                }
                {
                  dibitorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={debitorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = debitorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...debitorbalancedata}
                      />
                    </div> : ""
                }
                {
                  crediorbalancepopup ?
                    <div className='table animatingBottom' style={{ height: "340px", width: "100%" }}>
                      <DataGrid
                        rows={creditorbalancedata}
                        columns={debitorbalancedataclm}
                        pageSize={4}
                        onCellClick={handlePendingRfqsCellClick}
                        getRowId={(row) => row.MCODE}
                        // checkboxSelection
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const selectedRows = creditorbalancedata.filter((row) =>
                            selectedIDs.has(row.MCODE),

                          );

                          console.log(selectedRows)
                          setSelectedRows(selectedRows);
                          // setfirstname(selectedRows.mcode)

                        }}
                        {...creditorbalancedata}
                      />
                    </div> : ""
                }

              </div>
            </section>
          </div>
        </div>
        {/* ------- */}
        <div className="d">
        </div>
      </Grid>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}
import React, { useEffect, useState } from 'react'
import './help.css'
import useStyles from "./styles";
import { useTheme } from "@material-ui/styles";

function Help() {
    const classes = useStyles();
    const theme = useTheme();
    const [companyColor, setcompanyColor] = useState("");

    useEffect(()=>{
        setcompanyColor(localStorage.getItem('cmpnyType').split(',')[0])
    },[])


    return (
        <>
            <div className='context'>
                <div className='left'>
                    <h1>Call US</h1>
                    <p>+6388056383</p>
                    <h1>LOCATION</h1>
                    <p>H-196 Saitec Interantional</p>
                    <h1>BUSINESS HOURS</h1>
                    <p>Mon-fri !0am -8pm , Sat , Sun ..Closed</p>

                </div>
                <div className='right'>
                    <center><h2 className={`${classes[companyColor + 'BG']} contact_help_text`}>Contact Us</h2></center>
                    <div className='helpForm'>
                        <input type='text' className={`${classes[companyColor + 'BB']}`} placeholder='Enter Your Name.'  />
                        <input type='email' className={`${classes[companyColor + 'BB']}`} placeholder='Enter Your Email' />
                        <textarea className={`${classes[companyColor + 'BB']}`} placeholder='feel Free To Message...' rows="4" cols="30"></textarea>
                        <br />
                        <button  className={` helpSubmitBtn ${classes[companyColor?.split(',')[0]]} `}>SUBMIT</button>
                    </div>
                </div>


            </div>

            <div  className={` area ${classes[companyColor?.split(',')[0]]}  my-1`} >
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div >
        </>
    )
}

export default Help;
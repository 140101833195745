import React, { useState,useEffect } from "react";
import { DataGrid , GridColumn  } from "@mui/x-data-grid";
import useStyles from "./styles";
const ReportTbl=(props)=>
{
  
  const classes = useStyles();
  var thisCompany = props.headerColor?.split(",")[0];
 


   return(
    <div className={`table animatingBottom ${thisCompany}`} style={{height:"368px",width:"100%",background:'#fff'}}>

    <DataGrid
    rows={props.dataArray}
    columns={props.TblColumns}
    pageSize={10}
    getRowId={(row) => row.Id}
   
    className={`${classes[thisCompany]}` }
    
    onSelectionModelChange={(ids) => {
      const selectedIDs = new Set(ids);
      const selectedRows = props.dataArray.filter((row) =>
        selectedIDs.has(row.Id),
     
      );

    }}
      {...props.dataArray}
    />
    </div>
   )
}
export default ReportTbl;


import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import {
  Grid,
  LinearProgress,
  Select,
  OutlinedInput,
  MenuItem,
  Button
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { useTheme } from "@material-ui/styles";
import {
  Line
} from "recharts";
import { Modal,Box  } from '@mui/material';
import LineCharts from '../../../Utiles/Chart/Fasdf';
import axios from "axios";
// styles
import useStyles from "./styles";

// components
import mock from "./mock";
import noti from '../../../images/hrx.png'
import Widget from "../../../Utiles/Widget";
import PageTitle from "../../../Utiles/PageTitle";
import Form from ".././Item/components/form/Form"
import { Typography } from "../../../Utiles/Wrappers";
import Searchbar from "../../../Utiles/Searchbar/SearchBar";
import Alertmessage from "../../../Utiles/Alert/Alertmessage";
import TradersImg from '../../../images/traders.png';
import {GET_PENDING_INVOICE_COUNT,GET_CURRENT_LIVE_ENQUIRIS_COUNT,GET_PENDING_RFQ_COUNT,
  GET_TOP_CLIENT_ORDER_COUNT,GET_TOP_VENDOR_COUNT,GET_TOP_CLIENT_COUNT,GET_GRAPH_DATA, GET_CURRENT_ALL_LIVE_ENQUIRIS, GET_CURRENT_ALL_PENDING_RFQ, GET_ALL_PENDING_INVOICE, GET_DEBITOR_BALANCE, GET_CREDITOR_BALANCE, GET_DEBITOR_BALACNCE_COUNT, GET_CREDITOR_BALACNCE_COUNT, COMPANY_CODE} from '../../../constant';
  import CountUp from 'react-countup';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const mainChartData = getMainChartData();
const PieChartData = [
  { name: "Group A", value: 400, color: "primary" },
  { name: "Group B", value: 300, color: "secondary" },
  { name: "Group C", value: 300, color: "warning" },
  { name: "Group D", value: 200, color: "success" },
];

export default function Dashboard(props) {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [startdate,setstartdate] = useState("");
  const [enddate,setenddate] = useState("");
  const [todaysdate,settodaysdate] = useState("");
  const [openModal,setOpenModal] = useState(false);
  const [handleClose,setHandleClose] = useState(false);
  const [reportName,setReportName] = useState("");
  const [reportData,setReportData] = useState([]);
  const [isLoading,setisLoading] = useState(false)
  const [reportDataRow,setReportDataRow] = useState([]);
  // local
  const [mainChartState, setMainChartState] = useState("monthly");
  const [pendingInvoiceCount, setPendingInvoiceCount] = useState(0);
  const [currentLiveEnquiris, setCurrentLiveEnquiris] = useState(0);
  const [CurrentLiveEnquirispopupdata,setCurrentLiveEnquirispopupdata] = useState([])
  const [pendingRFQspopupdata,setpendingRFQspopupdata] = useState([])
  const [Pendingpopopupdata,setPendingpopopupdata] = useState([])
  const [debitorbalancedata,setdebitorbalancedata] = useState([])
  const [creditorbalancedata,setcreditorbalancedata] = useState([])
  const [pendingRFQ, setPendingRFQ] = useState(0);
  const [debitorcount, setdebitorcount] = useState(0);
  const [creditorcount, setcreditorcount] = useState(0)
  const [topClientOrderCount, setTopClientOrderCount] = useState(0);
  const [TopClientOrdername,setTopClientOrdername] = useState("")
  const [topVendorCount, setTopVendorCount] = useState(0);
  const [TopVendorname,setTopVendorname] = useState("")
  const [topCilentCount, setTopCilentCount] = useState(0);
  const [TopCilentname,setTopCilentname] = useState("")
  const [graphDate, setGraphDate] = useState([]);
  const [popupHeader,setpopupHeader] = useState("")
  const [pendingreqpopup,setpendingreqpopup] = useState(false)
  const [currentlivepopup,setcurrentlivepopup] =useState(false)
  const [pendingPOpopup,setpendingPOpopup] =useState(false)
  const [dibitorbalancepopup,setdibitorbalancepopup] = useState(false)
  const [crediorbalancepopup,setcrediorbalancepopup] = useState(false)
  const [SelectedRows,setSelectedRows] =useState([])
  const [isform,setisform] = useState(false);
  const [companyColor, setcompanyColor] = useState("")
  console.log(reportData);
const handleOpenReports=(title)=>
{
  
  setOpenModal(true);
  setReportName(title);
  
  if(title==="Current Live Enquiries")
  {
      //---------- GET CURRENT LIVE ALL ENQUIRIS FOR DASHBOERD----------------
      axios.get(GET_CURRENT_ALL_LIVE_ENQUIRIS).then((response)=>{
        if(response.data.IsSuccess === true){
          setReportData(response.data.Data);
          setReportDataRow(currentliveenquryclm);
        }
      })
  }
  else if(title==="Pending RFQs")
  {
      //---------- GET ALL PENDING RFQ FOR DASHBOERD----------------
      axios.get(GET_CURRENT_ALL_PENDING_RFQ).then((response)=>{
        if(response.data.IsSuccess === true){
          setReportData(response.data.Data);
          setReportDataRow(pendingRFQsclm);
        }
      })
  }
  else if(title==="PO Pending Invoice")
  {
      //---------- GET ALL PENDING INVOICE FOR DASHBOERD----------------
      axios.get(GET_ALL_PENDING_INVOICE).then((response)=>{
        debugger;
        setReportDataRow(currentliveenquryclm);
        if(response.data.IsSuccess === true){
          setReportData(response.data.Data);
        }
        else
        {
          setReportData([]);
        }
      })
  }
  else if(title==="Debitor balance")
  {
      //---------- GET Debitor balance FOR DASHBOERD----------------
      axios.get( GET_DEBITOR_BALANCE).then((response)=>{
        if(response.data.IsSuccess === true){
          setReportData(response.data.Data);
          setReportDataRow(debitorbalancedataclm);
        }
      })
  }
  else if(title==="Creditor balance")
  {
      //---------- GET CURRENT LIVE ALL ENQUIRIS FOR DASHBOERD----------------
      axios.get(GET_CREDITOR_BALANCE).then((response)=>{
        if(response.data.IsSuccess === true){
          setReportData(response.data.Data);
          setReportDataRow(debitorbalancedataclm);
        }
      })
  }
  
}

  const [popupclass,setpopupclass] = useState("display-none")
const currentliveenquryclm =[
  { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },
  { field: 'topClientName', headerName: 'CLIENT NAME', width: 240   },
  { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 170 },
 

]

const pendingRFQsclm =[
  { field: 'RequestNO', headerName: 'REQUEST NO', width: 150 },
  { field: 'topClientName', headerName: 'CLIENT NAME', width: 150   },
  { field: 'topVendorName', headerName: 'VENDOR NAME', width: 150   },
  { field: 'EnquirisOrderNumber', headerName: 'FINENTIAL YEAR', width: 150 },
]

const debitorbalancedataclm =[
  { field: 'MCODE', headerName: 'ACCOUNT CODE', width: 150   },
  { field: 'topClientName', headerName: 'ACCOUNT NAME', width: 150   },
  { field: 'Debit', headerName: 'DEBITED', width: 80 },
  { field: 'credit', headerName: 'CREDIT', width: 80 },
  { field: 'balance', headerName: 'BALANCE', width: 100 },
]

  useEffect(()=>{
// set company colour after gtting Comapny name from localstorage 
    setcompanyColor(localStorage.getItem('cmpnyType'))
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
      //---------- GET PENDING INVOICE FOR DASHBOERD----------------
        axios.post(GET_PENDING_INVOICE_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
      if(response.data.IsSuccess === true){
        console.log(response)
        setPendingInvoiceCount(response.data.Data.PendingInvoiceCount);
      }
    })

     //---------- GET Debitor balance FOR DASHBOERD----------------
     axios.post(GET_DEBITOR_BALACNCE_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
      console.log(response)
      if(response.data.IsSuccess === true){
        setdebitorcount(response.data.Data.debitorbalanceCount);
      }
    })
       //---------- GET Creditor balance FOR DASHBOERD----------------
       axios.post(GET_CREDITOR_BALACNCE_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
        console.log(response)
        if(response.data.IsSuccess === true){
          setcreditorcount(response.data.Data.creditorbalanceCount);
        }
      })
  //---------- GET CURRENT LIVE ENQUIRIS FOR DASHBOERD----------------
    axios.post(GET_CURRENT_LIVE_ENQUIRIS_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
      console.log(response)
      if(response.data.IsSuccess === true){
        setCurrentLiveEnquiris(response.data.Data.CurrentLiveEnquiris);
      }
    })
  

        //---------- GET TOP client order FOR DASHBOERD----------------
    axios.post(GET_TOP_CLIENT_ORDER_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
      console.log(response)
      if(response.data.IsSuccess === true){
        setTopClientOrderCount(response.data.Data.topClientOrderCount);
        setTopClientOrdername(response.data.Data.topClientOrderName);
      }
    })
     
    //---------- GET TOP VENDOR FOR DASHBOERD----------------
    axios.post(GET_TOP_VENDOR_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
      console.log(response)
      if(response.data.IsSuccess === true){
        setTopVendorCount(response.data.Data.topVendorCount)
        setTopVendorname(response.data.Data.topVendorName)
      }
    })

  //---------- GET TOP CLIENT FOR DASHBOERD----------------
  axios.post(GET_TOP_CLIENT_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
    console.log(response)
    if(response.data.IsSuccess === true){
      setTopCilentCount(response.data.Data.topCilentCount)
      setTopCilentname(response.data.Data.topClientName)
    }
  })
       //---------- GET PENDING RFQ FOR DASHBOERD----------------
       axios.post(GET_PENDING_RFQ_COUNT,{"CompanyNumber":COMPANY_CODE}).then((response)=>{
        if(response.data.IsSuccess === true){
         
          setPendingRFQ(response.data.Data.PendingRFQ)
        }
      })
          //---------- GET GRAPH DATA FOR DASHBOERD----------------
          axios.post(GET_GRAPH_DATA,{
            "fromDate":"01-03-2023",
            "toDate":"10-03-2023",
            "CompanyNumber":COMPANY_CODE
        }).then((response)=>{
            console.log(response)
            if(response.data.IsSuccess === true){
              console.log(response)
              setGraphDate(response.data.Data);
            }
          })
          const today = new Date();
          const threeMonthsAgo = new Date();
          threeMonthsAgo.setMonth(today.getMonth());
          
          const options = { month: '2-digit', day: '2-digit', year: 'numeric',  };
          const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
            .replace(/\//g, '-');
          
          console.log(formattedDate);
         
          settodaysdate(formattedDate)
  },[]);


 const getreports =() => {



  axios.post(GET_GRAPH_DATA,{
    "StartDate":startdate,
    "EndDate":enddate
}).then((response)=>{
    console.log(response)
    if(response.data.IsSuccess === true){
      setGraphDate(response.data.Data);
    }
    else{
      alert("No record Found !!")
    }
  })
 }




 const  getreportseightmonths = () => {
  const today = new Date();
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 8);

const options = { month: '2-digit', day: '2-digit', year: 'numeric',  };
const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
  .replace(/\//g, '-');

console.log(formattedDate);
  var datebeforenineMOnth = formattedDate;

  axios.post(GET_GRAPH_DATA,{
    "StartDate":todaysdate,
    "EndDate":datebeforenineMOnth
}).then((response)=>{
    console.log(response)
    if(response.data.IsSuccess === true){
      setGraphDate(response.data.Data);
    }
    else{
      alert("No record Found !!")
    }
  })

 }

 const  getreportssixmonths = () => {
  const today = new Date();
const threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(today.getMonth() - 6);

const options = { month: '2-digit', day: '2-digit', year: 'numeric',  };
const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
  .replace(/\//g, '-');


var datebeforesixMOnth = formattedDate;

axios.post(GET_GRAPH_DATA,{
  "StartDate":todaysdate,
  "EndDate":datebeforesixMOnth
}).then((response)=>{
  console.log(response)
  if(response.data.IsSuccess === true){
    setGraphDate(response.data.Data);
  }
  else{
    alert("No record Found !!")
  }
})
 }

 const  getreportsthreemonths = () => {
  const today = new Date();
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  
  const options = { month: '2-digit', day: '2-digit', year: 'numeric',  };
  const formattedDate = threeMonthsAgo.toLocaleDateString('en-US', options)
    .replace(/\//g, '-');

var datebeforethreeMOnth = formattedDate;

axios.post(GET_GRAPH_DATA,{
  "StartDate":todaysdate,
  "EndDate":datebeforethreeMOnth
}).then((response)=>{
  console.log(response)
  if(response.data.IsSuccess === true){
    setGraphDate(response.data.Data);
  }
  else{
    alert("No record Found !!")
  }
})
 }


 const handleclosepopup =() =>{
  setpopupclass("display-none")
 }
 const handleclosepopupfullscreen =()=>{
  setisform(false)
  // setpopupclass("display-block")
 }

 const handlecurrentliveenquirydata =()=>{
  setcurrentlivepopup(true)
  setpendingreqpopup(false)
  setpendingPOpopup(false)
  setcrediorbalancepopup(false)
  setdibitorbalancepopup(false)
  setpopupclass("display-block")
  setpopupHeader("Current Live Enquiries")
    //---------- GET CURRENT LIVE ALL ENQUIRIS FOR DASHBOERD----------------
    axios.get(GET_CURRENT_ALL_LIVE_ENQUIRIS).then((response)=>{
      console.log(response)
      if(response.data.IsSuccess === true){
        console.log(response.data.Data)
        setCurrentLiveEnquirispopupdata(response.data.Data)
        
      }
    })
 }

 const handlependingRFQs =()=>{
  setpopupclass("display-block")
  setpendingreqpopup(true)
  setcurrentlivepopup(false)
  setcrediorbalancepopup(false)
  setpendingPOpopup(false)
  setdibitorbalancepopup(false)
  setpopupHeader("Pending RFQs")
      //---------- GET PENDING RFQS ----------------
      axios.get(GET_CURRENT_ALL_PENDING_RFQ).then((response)=>{
        console.log(response)
        if(response.data.IsSuccess === true){
          console.log(response.data.Data)
          setpendingRFQspopupdata(response.data.Data)
          
        }
      })
  
 }

 const handlependingInvoice =()=>{
  setpendingreqpopup(false)
  setcurrentlivepopup(false)
  setpendingPOpopup(true)
  setcrediorbalancepopup(false)
  setdibitorbalancepopup(false)
  setpopupclass("display-block")
  setpopupHeader("PO Pending Invoice")
      //---------- GET PENDING Invoice ----------------
      axios.get(GET_ALL_PENDING_INVOICE).then((response)=>{
        debugger;
        if(response.data.IsSuccess === true){
          console.log(response.data.Data)
          setPendingpopopupdata(response.data.Data)
        }
      })
  
 }

const handledebitorBalance =()=>{
  setpopupclass("display-block")
  setdibitorbalancepopup(true)
  setcrediorbalancepopup(false)
  setpendingreqpopup(false)
  setcurrentlivepopup(false)
  setpendingPOpopup(false)
  setpopupHeader("Debitor balance")
   //---------- GET Debitor balance----------------
   axios.get(GET_DEBITOR_BALANCE).then((response)=>{
    console.log(response)
    if(response.data.IsSuccess === true){
      console.log(response.data.Data)
      setdebitorbalancedata(response.data.Data)

      
    }
  })
}

const handlecreditedbalance =()=>{
  setpopupclass("display-block")
  setdibitorbalancepopup(false)
  setcrediorbalancepopup(true)
  setpendingreqpopup(false)
  setcurrentlivepopup(false)
  setpendingPOpopup(false)
  setpopupHeader("Creditor balance")
   //---------- GET Debitor balance----------------
   axios.get(GET_CREDITOR_BALANCE).then((response)=>{
    console.log(response)
    if(response.data.IsSuccess === true){
      console.log(response.data.Data)
      setcreditorbalancedata(response.data.Data)

      
    }
  })

  
}

//  const removepopup =() =>{
//   setpopupclass("display-none")
//  }


const openRFQsForm =(a,b,c,d)=>{
  debugger;
console.log(a)
console.log(b)
console.log(c)
console.log(d)
}



const handlePendingRfqsCellClick =(params)=>{
  if(reportName==="Current Live Enquiries")
  {
    history.push({"path":'/CurrentLiveEnquiries',state:{rows:{SelectedRows} , header:{reportName}}});
  }
// console.log(params.row);
// setOpenModal(false);
// setisform(true)
// setpopupclass("display-none");
}

// exact data right top on dashboard 
var nowexactdate = function getFormettedDate() {
  var datentime = ""
  let today = new Date();

  // Get day, month, and year separately
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();

  // Create an array of month names
  let monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get the month name from the array
  let monthName = monthNames[month - 1];
  datentime = `${day} ${monthName} ${year}`
  return datentime

}

  return (
    <>
      {
            isLoading?  <div className='Commonloader'>
            </div>:null
          }
    <Searchbar />
 
       <div style={{right:0,top:0}} className="notify">
            <img height="20px" src={noti} />
          </div>
      <PageTitle 
      button={<Button
      variant="contained"
      size="medium"
      style={{marginTop:'20px'}}
      className={` ${classes[companyColor]}`}
    >
      {nowexactdate()}
    </Button>} />
    <Grid container spacing={2} className="orderdata py-2" >
      <Grid item lg={3} md={4} sm={4} xs={6} className="pb-1" onClick={e=>{handleOpenReports("Current Live Enquiries")}} >
          <div className={`card py-1 order_mangmnt_dashbord_CardH ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Current Live Enquiries</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12} className="py-2">
                  <h4  className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topClientOrderCount}/>
                  </h4>
                 
                </Grid>
               
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={4} xs={6} className="pb-1" onClick={e=>{handleOpenReports("Pending RFQs")}}>
          <div className={`card py-1 order_mangmnt_dashbord_CardH ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Pending RFQs</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12} className="py-2">
                  <h4  className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topVendorCount}/>
                  </h4>
                
                </Grid>
                
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={4} xs={6} className="pb-1" onClick={e=>{handleOpenReports("PO Pending Invoice")}}>
          <div className={`card py-1 order_mangmnt_dashbord_CardH ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>PO Pending Invoice</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12} className="py-2">
                  <h4  className={`fw-bold dashboard_card_number_counter ${classes.textCountInfo}  ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topCilentCount} />
                  </h4>
                 
                </Grid>
               
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={2} md={4} sm={4} xs={6} className="pb-1" onClick={e=>{handleOpenReports("Debitor balance")}}>
          <div className={`card py-1 order_mangmnt_dashbord_CardH ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Debitor Balance</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12} className="py-2">
                  <h4  className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topVendorCount}/>
                  </h4>
                 
                </Grid>
               
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={3} md={4} sm={4} xs={12} className="pb-1" onClick={e=>{handleOpenReports("Creditor balance")}}>
          <div className={`card py-1 order_mangmnt_dashbord_CardH ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Creditor Balance</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={12} className="py-2">
                  <h4  className={`fw-bold dashboard_card_number_counter ${classes.textCountInfo}  ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topCilentCount} />
                  </h4>
                
                </Grid>
              
              </Grid>
            </div>
          </div>
        </Grid>
        {
          isform ? 
          <div className="popupbg" >
            <section className='popup-fullscreen'>
            <p style={{color:"#fff",float:'right',fontWeight:"bold"}} className="crossicon"  onClick={handleclosepopupfullscreen}>&#10006;</p>
            <Form rows={SelectedRows}  header={reportName}/>
            </section>
       
          </div>
          :""
        }

        {/* ----popup-- */}
        <div className={popupclass}>
      <div className="popupbg" >
      <section className='popup-main'>
       <div style={{display:"flex",justifyContent:"space-between", width:"90%"}}>
       <h4 style={{color:"#fff"}}>{popupHeader}</h4>
        <p style={{color:"#fff"}}  className="crossicon" onClick={handleclosepopup}>&#10006;</p>
       </div>
    
      <div className="table-responsive">
      {
        pendingreqpopup ? 
      <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
      <DataGrid
      rows={pendingRFQspopupdata}
      columns={pendingRFQsclm}
      pageSize={4}
      onCellClick={handlePendingRfqsCellClick}
      getRowId={(row) => row.RequestNO}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = pendingRFQspopupdata.filter((row) =>
            selectedIDs.has(row.RequestNO),
         
          );
          
          console.log(selectedRows)                                                     
          setSelectedRows(selectedRows);
          // setfirstname(selectedRows.mcode)

        }}
        {...pendingRFQspopupdata}
      />
      </div>
      
      :
      ""}

      {
        currentlivepopup ?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
      <DataGrid
      rows={CurrentLiveEnquirispopupdata}
      columns={currentliveenquryclm}
      pageSize={4}
      onCellClick={handlePendingRfqsCellClick}
      getRowId={(row) => row.RequestNO}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = CurrentLiveEnquirispopupdata.filter((row) =>
            selectedIDs.has(row.RequestNO),
         
          );
          
          console.log(selectedRows)                                                     
          setSelectedRows(selectedRows);
          // setfirstname(selectedRows.mcode)

        }}
        {...CurrentLiveEnquirispopupdata}
      />
      </div>:""
      }
      {
        pendingPOpopup ? 
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={Pendingpopopupdata}
        columns={currentliveenquryclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.RequestNO}
          // checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = Pendingpopopupdata.filter((row) =>
              selectedIDs.has(row.RequestNO),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...Pendingpopopupdata}
        />
        </div>:""
      }
       {
        dibitorbalancepopup ?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={debitorbalancedata}
        columns={debitorbalancedataclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.MCODE}
          // checkboxSelection
          onSelectionModelChange={(ids) => {  
            const selectedIDs = new Set(ids);
            const selectedRows = debitorbalancedata.filter((row) =>
              selectedIDs.has(row.MCODE),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...debitorbalancedata}
        />
        </div>: ""
      }
      {
        crediorbalancepopup?
        <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
        <DataGrid
        rows={creditorbalancedata}
        columns={debitorbalancedataclm}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.MCODE}
          // checkboxSelection
          onSelectionModelChange={(ids) => {  
            const selectedIDs = new Set(ids);
            const selectedRows = creditorbalancedata.filter((row) =>
              selectedIDs.has(row.MCODE),
           
            );
            
            console.log(selectedRows)                                                     
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...creditorbalancedata}
        />
        </div>: ""
      }

      {/* add new modal  */}
       <Modal
  open={openModal}
  onClose={()=>{setOpenModal(false)}}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <Typography className="crossicon" variant="h6" component="span"onClick={()=>{setOpenModal(false)}} >
  &#10006;
    </Typography>
    <Typography id="modal-modal-title" variant="h5" component="h2" >
      {reportName}
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
    <div className='table animatingBottom' style={{height:"340px",width:"100%"}}>
      {reportName==="Debitor balance" ||reportName==="Creditor balance"?
        <DataGrid
        rows={reportData}
        columns={reportDataRow}
        pageSize={4}
        onCellClick={handlePendingRfqsCellClick}
        getRowId={(row) => row.MCODE}
          // checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            const selectedRows = reportData.filter((row) =>
              selectedIDs.has(row.MCODE),
            );
            setSelectedRows(selectedRows);
            // setfirstname(selectedRows.mcode)
  
          }}
          {...reportData}
        />
      :
      <DataGrid
      rows={reportData}
      columns={reportDataRow}
      pageSize={4}
      onCellClick={handlePendingRfqsCellClick}
      getRowId={(row) => row.RequestNO}
        // checkboxSelection
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          const selectedRows = reportData.filter((row) =>
            selectedIDs.has(row.RequestNO),
          );
          setSelectedRows(selectedRows);
          // setfirstname(selectedRows.mcode)

        }}
        {...reportData}
      />
      }
  
      </div>
    </Typography>
  </Box>
</Modal>  
  </div>
       
      </section>
      </div>
    </div>
    {/* ------- */}
        <div className="d">  
          </div>
          <Grid item lg={4} md={4} sm={4} xs={6} className="pb-1">
          <div className={`card py-1 order_mangmnt_dashbord_Card2H ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Top Sale To Party</h6>
              <Grid container item alignItems={"center"} style={{height:'120px'}}>
                <Grid item xs={3} className="py-5">
                  <h4  className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topClientOrderCount}/>
                  </h4>
                  <p style={{width:'140px',fontSize:'12px'}} >{TopClientOrdername}</p>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={TradersImg} className='dashboard_card_images' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={6} className="pb-1">
          <div className={`card py-1 order_mangmnt_dashbord_Card2H ${classes.row1Card}`}>
            <div className="card-body pb-0">
              <h6 className={`card-title my-0 fw-bold`}>Top Vendor</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={3} className="py-5">
                  <h4  className={`fw-bold dashboard_card_number_counter  ${classes.textCountInfo} ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topVendorCount}/>
                  </h4>
                  <p style={{width:'140px',fontSize:'12px'}}>{TopVendorname}</p>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={TradersImg} className='dashboard_card_images' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        <Grid item lg={4} md={4} sm={4} xs={12} className="pb-1">
          <div className={`card py-1 order_mangmnt_dashbord_Card2H ${classes.row1Card}`}>
            <div className="card-body ">
              <h6 className={`card-title my-0 fw-bold`}>Top Client</h6>
              <Grid container item alignItems={"center"}>
                <Grid item xs={3} className="py-5">
                  <h4  className={`fw-bold dashboard_card_number_counter ${classes.textCountInfo}  ${classes[companyColor]}`} weight="medium" noWrap>
                    <CountUp end={topCilentCount} />
                  </h4>
                  <p style={{width:'140px',fontSize:'12px'}}>{TopCilentname}</p>
                </Grid>
                <Grid item xs={6}>
                  <span >
                    <img src={TradersImg} className='dashboard_card_images ' /></span>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
        {/* {mock.bigStat.map(stat => (
          <Grid item md={4} sm={6} xs={12} key={stat.product}>
            <BigStat {...stat} />
          </Grid>
        ))} */}
        {/* <Grid item xs={12}>
          <Widget
            title="Support Requests"
            upperTitle
            noBodyPadding
            bodyClass={classes.tableWidget}
          >
            <Table data={mock.table} />
          </Widget>
        </Grid> */}
      </Grid>
 <div className="d-flexed">
 <div>

  <div className="datefilter">
  

  <div>
    <input 
      type="date"
       placeholder="Start Date"
        // className="start-input"
      value={startdate}
      onChange={(e) => setstartdate(e.target.value)}
    />
    <input
      type="date" 
      placeholder="End Date"
      //  className="End-input"
      value={enddate}
      onChange={(e) => setenddate(e.target.value)}
    />
    <button className={`${classes[companyColor +'BG']}`}  onClick={getreports}>Apply</button>
  </div>
  </div>
  </div>
   <div>
   <button style={{fontSize:"12px", backgroundColor: "#fff", color: 'Black',marginRight:'5px', padding: '8px 10px',height:"35px",width: "115px",border: "1px solid #CDCDCD", boxShadow: "0px 1px 1px rgba(83, 83, 83, 0.25)", borderRadius: "10px"}} onClick={getreportsthreemonths}>
        Last 3 months
    </button>
    <button style={{fontSize:"12px", backgroundColor: "#fff", color: 'Black',marginRight:'5px', padding: '8px 10px',height:"35px",width: "115px",border: "1px solid #CDCDCD",boxShadow: "0px 1px 1px rgba(83, 83, 83, 0.25)", borderRadius: "10px" }} onClick={getreportssixmonths}>
        Last 6 months
    </button>
    <button style={{fontSize:"12px", backgroundColor: "#fff", color: 'Black', padding: '8px 10px',height:"35px",width: "115px",border: "1px solid #CDCDCD", boxShadow: "0px 1px 1px rgba(83, 83, 83, 0.25)", borderRadius: "10px"}} onClick={getreportseightmonths}>
        Last 8 months
    </button>
   </div>
 </div>
      <LineCharts graphDate={graphDate}/>
    </>
  );
}

// #######################################################################
function getRandomData(length, min, max, multiplier = 10, maxDiff = 10) {
  const array = new Array(length).fill();
  let lastValue;

  return array.map((item, index) => {
    let randomValue = Math.floor(Math.random() * multiplier + 1);

    while (
      randomValue <= min ||
      randomValue >= max ||
      (lastValue && randomValue - lastValue > maxDiff)
    ) {
      randomValue = Math.floor(Math.random() * multiplier + 1);
    }

    lastValue = randomValue;

    return { value: randomValue };
  });
}

function getMainChartData() {
  const resultArray = [];
  const tablet = getRandomData(31, 3500, 6500, 7500, 1000);
  const desktop = getRandomData(31, 1500, 7500, 7500, 1500);
  const mobile = getRandomData(31, 1500, 7500, 7500, 1500);


  for (let i = 0; i < tablet.length; i++) {
    resultArray.push({
      tablet: tablet[i].value,
      desktop: desktop[i].value,
      mobile: mobile[i].value,
    });
  }

  return resultArray;
}

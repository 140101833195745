import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import LibraryBooksTwoToneIcon from '@material-ui/icons/LibraryBooksTwoTone';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import HeadsetMicOutlinedIcon from '@material-ui/icons/HeadsetMicOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import LogoImg from '../../../../images/logo.svg';
import IconImg from '../../../../images/posLOGO.png';
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
// styles
import useStyles from "./style";
const Sidebar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const [activeIndex, setActiveIndex] = useState(0);
    const [stepHeight, setStepHeight] = useState(0);
    const sidebarRef = useRef();
    const indicatorRef = useRef();
    const location = useLocation();
    const [reportmenu,setreportmenu] = useState("d-none")
    const [isReprtOpen,setIsReprtOpen] = useState(false);

    useEffect(() => {

        // var favicon = document.querySelector("link[rel~='icon']");
        // favicon.href = IconImg;

        var favicon = document.querySelector("link[rel~='icon']");
        favicon.href = IconImg;
        document.documentElement.style.setProperty('--thumb-bg-color', `#2fbaa8`);
        // document.querySelector('.TOGGLEicon').click();
    //     setTimeout(() => {
    //         const sidebarItem = sidebarRef.current.querySelector('.sidebar__menu__item');
    //         indicatorRef.current.style.height = `${sidebarItem.clientHeight}px`;
    //         setStepHeight(sidebarItem.clientHeight);
    //     }, 50);
     }, []);

     const handlelogoutPopup =() => {   
        props.onLogout(true);
    }

    // change active index
    // useEffect(() => {
    //     const curPath = window.location.pathname.split('/')[1];
    //     const activeItem = sidebarNavItems.findIndex(item => item.section === curPath);
    //     setActiveIndex(curPath.length === 0 ? 0 : activeItem);
    // }, [location]);

 

const handlerepportsubmenu =()=>{

if(reportmenu == "d-none"){
    setreportmenu("reportsSubmenu")
}
else{
    setreportmenu("d-none")
}



    
}
const sidebarList=[
    {"name":"Dashboard",
     "link":"/dashboard",
     "icon":<HomeOutlinedIcon/>,
    },
    {"name":"Approvals",
    "link":"/Approvals",
    "icon":<LibraryBooksTwoToneIcon/>,
   },
   {"name":"Open Enquiries",
   "link":"/OpenEnquiries",
   "icon": <LiveHelpOutlinedIcon/>,
  },
  {"name":"Reports",
  "link":"/reports",
  "icon": <LiveHelpOutlinedIcon/>,
 },
 {"name":"Help",
 "link":"/help",
 "icon": <LiveHelpOutlinedIcon/>,
},
];
const ReoprtList=[
    {"name":"Collection Report",
     "link":"/collectionReport",
    },
    {"name":"Account Status",
    "link":"/AccountStatus",
   },
   {"name":"General Ledger",
   "link":"/generalLeadure",
  },
  {"name":"Party Stock Ledger",
  "link":"/PartyStockLedger",
 },
 {"name":"Trail Balance",
 "link":"/TrailBalance",
},
{"name":"Ageing Analysis",
"link":"/AgeingAnalysis",
},
{"name":"Gstr",
"link":"/Gstr",
},
];
const onHandelSidPopoUp=()=>
{
    if(isReprtOpen)
    {
        setIsReprtOpen(false);
    }
    else{
    setIsReprtOpen(true);
    }
}
    return <div className={classes.sidebar}>
        <div className={classes.sidebarLogo}>
        <div style={{ textAlign: "center" }}>
         <div className={classes.sidelogo}> 
          {/* <p className={classes.sideMainText}>SaiTec</p> */}
          
      {/* <img src={LogoImg} alt="Example Image" style={{ width: "230px", height: "45px" ,marginRight:"10px"}} /> */}
      <img src={IconImg} alt="icon" className={classes.userSideImg} />
      {/* <p className={`fs-5 mb-0 fw-bold ${classes.textPrimary}`}> Ankur Sinha </p>
      <p className={`fs-6 fw-bold ${classes.textSecondry}`}>ankursinha@gmail.com</p> */}
      </div>
      </div>  
        </div>
        <div ref={sidebarRef} className={`p-3 ${classes.sidebarMenu}`}>
        <Link to="/dashboard" >
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                                <span className={classes.sidebarMenuItemIcon}>
                                <HomeOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Dashboard
                                </span>
                            </div>
                            </Link>
                            {/* <Link to="/Approvals" >
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>
                                <span className={classes.sidebarMenuItemIcon}>
                                <LibraryBooksTwoToneIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Approvals
                                </span>


                            </div>
                            </Link>
                            <Link to="/OpenEnquiries" >
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>
                                <LiveHelpOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Open Enquiries
                                </span>


                            </div>
                            </Link> */}
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                                <span className={classes.sidebarMenuItemIcon}>
                                <LiveHelpOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText} onClick={e=>{onHandelSidPopoUp(e)}}>
                                Reports
                                </span>
                            </div>
                            {
                             isReprtOpen?
                             <div  className={classes.subMenu}>
                                {
                                    ReoprtList.map((data,i)=>
                                    {
                                        return(   
                                        <Link to={data.link} >
                                        <div  className={`my-2 ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                                         
                                            <span className={classes.sidebarMenuItemText}>
                                           {data.name}
                                            </span>
                                        </div>
                                        </Link>
                                         )
                                    })
                                }
                                   </div>:""
                    }
                            <Link to="/help" >
                            <div  className={`my-2 ${classes.sidebarMenuItem}`}>{/*`${activeIndex ? '' : ''}`*/}
                                <span className={classes.sidebarMenuItemIcon}>
                                <LiveHelpOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Help
                                </span>


                            </div>
                            </Link>
                     <div className={`mt-3 ${classes.lowerMenu}`}>
                     {/* <Link to="/"> */}
                      <div  className={`my-2 ${classes.sidebarMenuItem}`} onClick={handlelogoutPopup}>{/*`${activeIndex ? '' : ''}`*/}
                                <span className={classes.sidebarMenuItemIcon}>
                                <ExitToAppOutlinedIcon/>
                                </span>
                                <span className={classes.sidebarMenuItemText}>
                                Logout
                                </span>
                            </div>
                    {/* </Link> */}
                     </div>
                  

                        {/* <Link to="/Logout" >
                        <div  className={`sidebar__menu__item ${activeIndex ? '' : ''}`} >
                            <div className="sidebar__menu__item__icon">
                            <ExitToAppOutlinedIcon/>
                            </div>
                            <div className="sidebar__menu__item__text">
                            LOGOUT
                            </div>
                        </div>
                        </Link> */}
                    <p className={`fs-6 text-white position-absolute bottom-0`}>Financial Year 2022 - 2023</p>
        </div>
    </div>;
};

export default Sidebar;
